import { lazy } from 'react';

const options = [
  {
    key: Math.random(),
    path: '/user',
    component: lazy(() => import('../user/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/category',
    component: lazy(() => import('../category/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/unit',
    component: lazy(() => import('../unit/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/project',
    component: lazy(() => import('../project/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/contact',
    component: lazy(() => import('../contact/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/item',
    component: lazy(() => import('../item/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../transaction/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/transaction-statement',
    component: lazy(() => import('../statement/List')),
    exact: true,
  },
];
export default options;
