/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';

import { observer, inject } from 'mobx-react';
import {
  Layout, Menu, Button, Col,
  Popover, List, Avatar,
} from 'antd';

import {
  HeartFilled,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  ContactsOutlined,
  AppstoreOutlined,
  ApartmentOutlined,
  TransactionOutlined,
  FileProtectOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';

import debounce from 'lodash/debounce';
import Loading from '../basic/Loading';
import routes from './dashboardRoutes';
import largeLogo from '../../assets/images/inventory-logo.png';

import './dashboard.css';

const {
  Header, Content, Footer, Sider,
} = Layout;

@observer
@inject('userStore', 'tokenStore', 'sectionsStore')
class Dashboard extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({
      collapsed: false,
      windowsWidth: 0,
      defualtMenu: 'visitlist',
      UIFlags: {},
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const { userStore, tokenStore, sectionsStore } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
      ]).then(() => { });
    };

    this.updateDimensions = debounce(() => {
      this.setState({ windowsWidth: window.innerWidth });
      const { windowsWidth } = this.state;
      if (windowsWidth < 992) {
        this.setState({ collapsed: true });
      } else {
        this.setState({ collapsed: false });
      }
    }, 300);
  }

  componentDidMount() {
    // this.setState({
    //   UIFlags: this.props.userStore.value.role_data
    //     ? (this.props.userStore.value.role_data.UIFlags || {}) : {},
    // });
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const {
      collapsed,
    } = this.state;
    const { location } = this.props;

    return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            theme="dark"
            style={{
              height: '100vh',
              left: 0,
              position: 'fixed',
              overflow: 'auto',
              background: '#2d3546',
            }}
            width={collapsed ? 0 : 240}
          >
            <div
              className="logo"
              style={{
                padding: ' 19px 0 15px 0',
                textAlign: 'center',
                background: '#1f2532',
              }}
            >
              <img alt="lucid-logo" style={{ width: 150 }} src={largeLogo} />
            </div>
            <Menu
              theme="dark"
              selectedKeys={[location.pathname]}
              mode="vertical"
              style={{ background: '#2d3646', marginTop: 35 }}
            >
              <Menu.Item key="/">
                <TransactionOutlined />
                Transaction
                <Link to="/" />
              </Menu.Item>

              <Menu.Item key="/transaction-statement">
                <FileProtectOutlined />
                Transaction Statement
                <Link to="/transaction-statement" />
              </Menu.Item>

              <Menu.Item key="/item">
                <UnorderedListOutlined />
                Items
                <Link to="/item" />
              </Menu.Item>

              <Menu.Item key="/contact">
                <ContactsOutlined />
                Contact
                <Link to="/contact" />
              </Menu.Item>

              <Menu.Item key="/project">
                <ReconciliationOutlined />
                Project
                <Link to="/project" />
              </Menu.Item>

              <Menu.Item key="/category">
                <AppstoreOutlined />
                Category
                <Link to="/category" />
              </Menu.Item>

              <Menu.Item key="/unit">
                <ApartmentOutlined />
                Unit
                <Link to="/unit" />
              </Menu.Item>

              <Menu.Item key="/user">
                <UserOutlined />
                User
                <Link to="/user" />
              </Menu.Item>

              {/* {UIFlags.admindoctorlist ? (
                <Menu.Item key="/">
                  <UserAddOutlined />
                  Doctor
                  <Link to="/" />
                </Menu.Item>
              ) : null} */}

            </Menu>

          </Sider>
          <Layout
            className="site-layout"
            style={{
              transition: 'all 0.2s',
              marginLeft: collapsed ? 0 : 240,
            }}
          >
            <Header
              className="site-layout-background"
              style={{ padding: 0, display: 'inherit' }}
            >
              <Col span={12} style={{ textAlign: 'start', paddingLeft: 10 }}>
                <Button
                  type="link"
                  icon={
                    <MenuOutlined style={{ fontSize: 15, color: '#1f2532' }} />
                  }
                  onClick={this.toggleSidebar}
                />
              </Col>
              <Col span={12} style={{ textAlign: 'end', paddingRight: 15 }}>
                <Popover
                  content={(
                    <List>
                      <List.Item>
                        <Button
                          onClick={this.logout}
                          icon={<LogoutOutlined />}
                          danger
                          type="link"
                          block
                        >
                          Logout
                        </Button>
                      </List.Item>
                    </List>
                  )}
                  trigger="hover"
                  arrowPointAtCenter
                  placement="bottomLeft"
                >
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.2) -1px 0px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                  />
                </Popover>

              </Col>
            </Header>
            <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 500 }}
              >
                <Suspense fallback={<Loading />}>
                  <Switch>
                    {routes.map((route) => (
                      <Route
                        exact={route.exact}
                        key={route.key}
                        path={`${route.path}`}
                      >
                        <route.component />
                      </Route>
                    ))}
                  </Switch>
                </Suspense>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <span>Developed with </span>
              <HeartFilled />
              {' '}
              By&nbsp;
              <a href="https://lucid-source.com" title="Lucid Source">
                Lucid Source
              </a>
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
