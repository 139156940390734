import React from 'react';
import {
  Row, Col, Card, Typography, Image,
} from 'antd';
import { HeartFilled } from '@ant-design/icons';
import { observer, inject } from 'mobx-react';
import debounce from 'lodash/debounce';
import sideImage from '../../assets/images/Inventory.png';
import Login from './Login';

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class LoginManager extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({

      windowsWidth: 0,
    });

    this.state = this.initialState();

    this.updateDimensions = debounce(() => {
      this.setState({ windowsWidth: window.innerWidth });
    }, 300);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { windowsWidth } = this.state;
    const { tokenStore, to } = this.props;
    if (`${tokenStore.value}`.trim() !== '') {
      return to;
    }
    return (
      <Row
        style={{ position: 'absolute', height: '100%', width: '100%' }}
        type="flex"
        align="middle"
      >
        <Col lg={15} md={12} sm={0} xs={0} style={{ backgroundColor: '#1e2832', height: '100%' }}>
          {/* <div
            style={{
              height: '70%',
              position: 'absolute',
              width: '80%',
              right: 0,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundImage: `url('${sideImage}')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            { windowsWidth > 768 ? (
              <Row
                style={{ position: 'absolute', height: '100%', width: '100%' }}
                type="flex"
                align="middle"
              >
                <Col span={24} style={{ marginTop: '70%' }}>
                  <Typography.Paragraph
                    align="center"
                    style={{ color: '#fff' }}
                  >
                    <span>Developed with </span>
                    <HeartFilled style={{ color: 'red' }} />
                    {' '}
                    By&nbsp;
                    <a href="https://lucid-source.com" title="Lucid Source">
                      Lucid Source
                    </a>
                  </Typography.Paragraph>
                </Col>
              </Row>
            ) : null }
          </div> */}

          <div style={{
            height: '80vh',
            width: '100%',
            display: 'block',
          }}
          >
            <Image
              width={700}
              src={sideImage}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
          <div style={{
            height: '20vh',
            width: '100%',
            display: 'block',
            position: 'relative',
          }}
          >
            <Typography.Paragraph
              align="center"
              style={{
                top: '50%',
                left: '50%',
                color: '#fff',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <span>Developed with </span>
              <HeartFilled style={{ color: 'red' }} />
              {' '}
              By&nbsp;
              <a href="https://lucid-source.com" title="Lucid Source">
                Lucid Source
              </a>
            </Typography.Paragraph>
          </div>
        </Col>
        <Col lg={9} md={12} sm={24} xs={24}>
          <Row>
            <Col span={24}>
              <Typography.Title
                align="center"
                color="primary"
                style={{ color: '#34d698', letterSpacing: '1.5px' }}
              >
                Inventory Managment
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Card bordered={false}>
                <Login />
              </Card>
            </Col>
            <Col span={24}>
              { windowsWidth < 768 ? (
                <Row
                  style={{ position: 'absolute', height: '100%', width: '100%' }}
                >
                  <Col span={24}>
                    <Typography.Paragraph
                      align="center"
                      type="secondary"
                    >
                      <span>Developed with </span>
                      <HeartFilled style={{ color: 'red' }} />
                      {' '}
                      By&nbsp;
                      <a href="https://lucid-source.com" title="Lucid Source">
                        Lucid Source
                      </a>
                    </Typography.Paragraph>
                  </Col>
                </Row>
              ) : null }
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LoginManager;
